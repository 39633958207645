import React from "react";
import FocusPointsJson from "../jsonData/FocusPointsJson";
const FocusCard = () => {
  return (
    <>
      <section className="howworksection">
        <div className="container">
          <div className="row g-8 g-lg-4">
            {FocusPointsJson.map((value, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="card focusCard">
                  <div className="count d-flex align-items-center justify-content-center mx-auto">
                    <h2 className="d-flex align-items-center justify-content-center">
                      {value.id}
                    </h2>
                  </div>
                  <div className="card-body text-center ">
                    <h2 className=" font-24px font-weight-700 mb-3">
                      {value.title}
                    </h2>
                    <p className=" font-20px font-weight-400">
                      {value.Discripiton}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FocusCard;
