import { createContext, useState } from "react";

export const Themecontext = createContext();

export const ThemeProvider = (props) => {
  const [isdark, setdark] = useState(false);
  return (
    <Themecontext.Provider value={{ isdark, setdark }}>
      {props.children}
    </Themecontext.Provider>
  );
};
