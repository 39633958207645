const SocialLinksJson = [
  {
    socialName: "fab fa-facebook-f",
    socialLink: "https://www.facebook.com",
  },
  {
    socialName: "fab fa-twitter",
    socialLink: "https://twitter.com/BodyneticAI",
  },
  {
    socialName: "fab fa-instagram",
    socialLink: "https://www.instagram.com/bodynetic.ai/",
  },
  {
    socialName: "fab fa-google",
    socialLink: "https://www.google.com",
  },
  {
    socialName: "fab fa-linkedin",
    socialLink: "https://www.linkedin.com/company/bodyneticfitnessapp/",
  },
];

export default SocialLinksJson;
