import brand1 from "../../assets/images/brands/1.png";
import brand2 from "../../assets/images/brands/2.jpg";
import brand3 from "../../assets/images/brands/3.png";
import brand4 from "../../assets/images/brands/4.jpg";
import brand5 from "../../assets/images/brands/5.jpg";
import brand6 from "../../assets/images/brands/6.png";
const BrandsJson = [
  {
    brandImage: brand1,
  },
  {
    brandImage: brand2,
  },
  {
    brandImage: brand3,
  },
  {
    brandImage: brand4,
  },
  {
    brandImage: brand5,
  },
  {
    brandImage: brand6,
  },
];

export default BrandsJson;
