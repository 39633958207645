const FocusPointsJson = [
  {
    id: 1,
    title: "Posture Issues",
    Discripiton:
      "Forward neck, knock or bow legs or simply want to improve your overall posture for your self-image.",
  },
  {
    id: 2,
    title: "Pain and chronic problems",
    Discripiton:
      "Common neck and shoulder aches, low back pains or having bad sleep due to tension headaches.",
  },
  {
    id: 3,
    title: "Cardiovascular and general health issues",
    Discripiton:
      "Heart diseases, diabetes and dementia are some of the areas that with exercise can improve these conditions",
  },
];

export default FocusPointsJson;
