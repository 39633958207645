import React from "react";
import WeDifferentJson from "../jsonData/WeDifferentJson";
import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
const WeDifferent = () => {
  return (
    <>
      <section
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
      >
        <div className="container">
          <div className="text-center">
            <h1 className=" font-25px font-md-36px font-weight-600">
              How are we different?
            </h1>
            <div className="hb-line mx-auto"></div>
          </div>
          <br />
          <br />
          <div className="row g-5 justify-content-center">
            {WeDifferentJson.map((value, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="WeDifferentCard card text-center border-0">
                  <div className="card-body">
                    <img src={value.image} alt="" className="mx-auto mb-3" />
                    <h4 className=" font-24px font-weight-700">
                      {value.title}
                    </h4>
                    <p>{value.discription}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default WeDifferent;
