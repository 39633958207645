import React, { useContext } from "react";
import aboutimg from "../../assets/images/about.png";
import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
import { Themecontext } from "../../themeContext";
const About = () => {
  const { isdark, setdark } = useContext(Themecontext);
  return (
    <>
      <section
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
      >
        <div className="container">
          <div className="row g-3 align-items-center">
            <div className="col-lg-4">
              <img src={aboutimg} alt="" />
            </div>
            <div className="col-lg-8">
              <div
                className="card p-3 p-md-5 border-radius-8px border-0"
                style={
                  isdark === true
                    ? { backgroundColor: "#1e222d" }
                    : { backgroundColor: "var(--primary-clr)" }
                }
              >
                <div className="card-body">
                  <h2 className=" font-24px font-weight-700 text-white">
                    EMILE DUMONT
                  </h2>
                  <h4 className=" font-16px font-weight-600 text-white">
                    Our Founder
                  </h4>
                  <p className=" font-20px text-white mt-3 mb-0">
                    We have a mission for the future: We want to empower and
                    inspire healthier communities by connecting people to
                    superior health and wellness programs. We want to be an
                    inclusive solution to allow the underserved communities
                    access to such programs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
