import React, { useContext, useState } from "react";
import HeaderLogo from "../../assets/images/logo.svg";
import HeaderLogoDark from "../../assets/images/logodark.png";
import { Link, NavLink } from "react-router-dom";
import ReactSwitch from "../reactSwitch/ReactSwitch";
import { Themecontext } from "../../themeContext";

const Header = () => {
  const [stickyClass, setStickyClass] = useState(false);
  const { isdark, setdark } = useContext(Themecontext);

  const stickNavbar = () => {
    if (window.scrollY >= 150) {
      setStickyClass(true);
    } else {
      setStickyClass(false);
    }
  };
  window.addEventListener("scroll", stickNavbar);

  //console.log(isdark);
  return (
    <>
      <div className={`cover-navbar ${stickyClass ? "nav-sticky" : ""}`}>
        <nav
          className="navbar navbar-expand-lg"
          aria-label="Offcanvas navbar large"
        >
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={isdark === true ? HeaderLogoDark : HeaderLogo} alt="" />
            </Link>
            <button
              className="navbar-toggler d-lg-none border-0 p-0"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar2"
              aria-controls="offcanvasNavbar2"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <div className="humburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasNavbar2"
              aria-labelledby="offcanvasNavbar2Label"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbar2Label">
                  <img src={HeaderLogo} alt="" style={{ width: "50%" }} />
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav align-items-center justify-content-end flex-grow-1">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/" end>
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/how-it-works">
                      How it works?
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about">
                      About us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/faq">
                      FAQ’s
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <ReactSwitch />
                  </li>

                  <li className="nav-item">
                    <a href="#contact" className="secondary-btn shadow-btn">
                      Get in touch!
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
