import React from "react";
import HeroSlider from "../component/slider/HeroSlider";
import OurPartnerClient from "../component/ourpartner/OurPartnerClient";
import WhyChooseUs from "../component/whychooseus/WhyChooseUs";
import OurSolution from "../component/oursolution/OurSolution";
import WeDifferent from "../component/wediffrent/WeDifferent";
import Testimonial from "../component/testimonial/Testimonial";
const Homepage = () => {
  return (
    <>
      <HeroSlider />
      <OurPartnerClient />
      <WhyChooseUs />
      <OurSolution />
      <WeDifferent />
      <Testimonial />
    </>
  );
};

export default Homepage;
