import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import TestimonialJson from "../jsonData/TestimonialJson";
import { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import quote from "../../assets/images/testimonial/quote.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Themecontext } from "../../themeContext";
const Testimonial = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );
  const { isdark, setdark } = useContext(Themecontext);

  //console.log(matches);
  useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      <section className="light-gray-bg pb-5 pb-lg-13">
        <div className="container">
          <div className="text-center width-100">
            <h3 className=" font-25px font-md-36px font-weight-600">
              Successful Stories
            </h3>
            <div className="hb-line mx-auto"></div>
          </div>
          <br />
          <br />
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            navigation
            spaceBetween={50}
            slidesPerView={1}
            autoHeight={true}
            loop={true}
            speed={1200}
            grabCursor
            pagination
            className="testimonialSlider pb-8 pt-10 pt-md-7 pt-lg-15"
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
          >
            {TestimonialJson.map((value, index) => (
              <SwiperSlide key={index}>
                <div className="card border-0 bg-transparent">
                  <img
                    src={value.image}
                    alt=""
                    className=" position-absolute top-0 d-none d-md-block border-radius-10px width-md-20 width-lg-25"
                    style={{
                      boxShadow: "2px 4px 8px 2px #15151540",
                      left: `${matches === true ? "90px" : "0px"}`,
                    }}
                  />
                  <div
                    className="card-body p-3 p-md-5 border-radius-8px width-100 width-md-90 width-lg-65 ps-3 ps-md-14 mt-0 mt-md-7 mx-auto me-lg-13"
                    style={
                      isdark === true
                        ? { backgroundColor: "#E0A800" }
                        : { backgroundColor: "var(--primary-clr)" }
                    }
                  >
                    <div className="d-flex align-items-center gap-4 mb-3 ">
                      <div className="position-relative d-lg-none flex-shrink-0">
                        <img
                          src={value.image}
                          alt=""
                          className=" border-radius-50px border-1 secondary-border"
                          style={{
                            width: "92px",
                            borderStyle: "solid",
                            objectFit: "cover",
                          }}
                        />
                        <img
                          src={quote}
                          alt=""
                          className=" position-absolute bottom-0 end-0"
                          style={{ width: "29px" }}
                        />
                      </div>
                      <div>
                        <h2 className=" font-24px font-weight-700 text-white">
                          {value.name}
                        </h2>
                        <h4 className="font-16px font-wight-600 text-white">
                          {value.occupation}
                        </h4>
                      </div>
                    </div>
                    <p className="text-white font-17px font-md-20px">
                      {value.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
