import React, { useContext } from "react";
import whychooseimg from "../../assets/images/whychoose.png";
import whychoosedarkimg from "../../assets/images/whychoosedark.png";

import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
import { Themecontext } from "../../themeContext";
const About = () => {
  const { isdark, setdark } = useContext(Themecontext);
  return (
    <>
      <section
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6 order-1 order-lg-0">
              <h3 className="font-20px font-weight-600 text-uppercase">
                About Us
              </h3>
              <h2 className=" font-25px font-md-36px font-weight-700 primary-text text-uppercase mb-3">
                WHY CHOOSE US?{" "}
              </h2>
              <ul className="disc-items">
                <li>
                  The Bodynetic is a registered social enterprise that focuses
                  on wholistic fitness, wellness and education for everyone.
                </li>
                <li>
                  Our services includes Posture Analysis, Fitness Testing,
                  Sports Massage and High Performance Coaching. We also provide
                  fully customised programs for corporates, performing arts
                  companies and sports teams.{" "}
                </li>
                <li>
                  We aim to have greater social impact by working with persons
                  with disabilities, youth at risk and the general population.
                </li>
              </ul>
              <ul className=" mt-4">
                <li>
                  <a href="#" className="secondary-btn extra-btn-padding-25">
                    Learn More
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <img
                src={isdark === true ? whychoosedarkimg : whychooseimg}
                alt=""
                className="mx-auto width-70 width-md-50 width-lg-100"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
