import React from "react";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import About from "../component/about/About";
import OurStory from "../component/ourStory/OurStory";
import TeamMembers from "../component/teamMembers/TeamMembers";
const AboutPage = () => {
  return (
    <>
      <Breadcrumb
        title="A painless world with better lifestyles and accessible preventive solutions."
        height="70vh"
      />
      <About />
      <OurStory />

      <TeamMembers />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
    </>
  );
};

export default AboutPage;
