import React, { useEffect } from "react";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage";
import HowWorkPage from "./pages/HowWorkPage";
import AboutPage from "./pages/AboutPage";
import FaqPage from "./pages/FaqPage";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  };
  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/how-it-works" element={<HowWorkPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route
            path="*"
            element={
              <>
                <section>
                  <div className="container">
                    <div className="text-center">
                      <h1 className=" font-weight-600">
                        Page <span className=" primary-text">Not Found.</span>
                      </h1>
                    </div>
                  </div>
                </section>
              </>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
