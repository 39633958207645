import team1 from "../../assets/images/team/Emile Dumont [Founder _ CEO].jpg";
import team2 from "../../assets/images/team/Edwin Gan [COO].jpg";
import team3 from "../../assets/images/team/Robin Soon [Head of Performance].jpg";
import team4 from "../../assets/images/team/Clara Tan [Trainer].jpg";
import team5 from "../../assets/images/team/Honey Moe Oo [Trainer].jpg";
import team6 from "../../assets/images/team/Jaclyn Low [Trainer].jpg";
import team7 from "../../assets/images/team/Michelle Loo [Trainer].jpg";
import team8 from "../../assets/images/team/Norika Tan [Marketing Executive].jpg";

const TeamMemberJson = [
  {
    image: team1,
    name: "Emile Dumont ",
    occupation: "Founder _ CEO",
    discription:
      "Emile Dumont is a sports scientist and entrepreneur with a strong advocate in sports and wellness education. He founded The Posture Lab (now Bodynetic) in 2017, with a mission to build a sustainable ecosystem around health, wellness and education. \n\n He holds a Bachelor's degree in Exercise and Sports Science from Edith Cowan University, Australia. He is also a dance advisor at Sheng Hong Arts Institute, advisor to the Goalball Society Singapore and an awardee of the Singapore Youth Entrepreneurs Awards in 2021 and recently participated in the Sustainable Impact Accelerator by Quest Ventures and raiSE. He is on a mission to have 50% of the business to have an impact in good health and wellbeing and skills development for low income families, people with disabilities and provide job opportunities to these communities.",
  },
  {
    image: team2,
    name: "Edwin Gan",
    occupation: "COO",
    discription:
      "Edwin is the Chief Operating Officer (COO) of The Posture Lab, an organization with the mission of fixing bodies and empowering clients with the knowledge of how their body works so that they can live pain free. \n\n He is responsible for the organization’s daily operations and ensuring that all of the systems, resources and manpower are in place to achieve The Posture Lab’s vision and mission. Graduated from the University of Edith Cowan with a Bachelor’s degree in Exercise and Sports Science, he also doubles up as a Sports Therapist with The Posture Lab with over 5 years of experience within the health and fitness industry. \n\n He has worked with clients from different walks of life, ranging from elite athletes who compete at the highest level of sporting events, sedentary individuals who have issues with their day to day movement, persons with disabilities and performing artistes.",
  },
  {
    image: team3,
    name: "Robin Soon ",
    occupation: "Head of Performance",
    discription:
      "Robin Soon is the head strength and conditioning coach of Bodynetic. He holds a Bachelor degree in Sports Science and Management. With particular knowledge in the development and execution of said niche programs in posture and health for both athletes and the general population. He is passionate about sports science and its implication for both health and performance in the everyday lives of our population. Having vast experience working with dancers, national and amateur athletes and general populations.",
  },
  {
    image: team4,
    name: "Clara Tan",
    occupation: "Trainer",
    discription:
      "Clara, she’s friendly and relaxed with a passion for sports and dance. With 8 years of experience in hockey, she has been coached by both national players and coaches. She holds a Bachelor degree in Sports Science and Management specializing in Sports Science from National Technological University. She finds joy in applying what she has been taught to real life scenarios. Her family and friends get to enjoy the insights on how to stretch optimally, aid recovery from injuries and most importantly the best foods for consumption fit for their body type and personal needs.",
  },
  {
    image: team5,
    name: "Honey Moe Oo",
    occupation: "Trainer",
    discription:
      "Honey’s passion for health and wellness was ignited through her years of dancing. She holds a Bachelor degree in Sports Science and Management specializing in Sports Science from National Technological University. With vast experience in different dance genres, from Bharatanatyam to Hip Hop. It has enabled her to understand the body better and how the different muscle groups are in higher demand for different genres. With such knowledge, it allows her to recognise your needs to ensure efficiency in recovery and training.",
  },
  {
    image: team6,
    name: "Jaclyn Low",
    occupation: "Trainer",
    discription:
      "Jaclyn is an individual with a passion in dance. Through her vast experiences obtained during her journey as a dancer, it piqued her interest in sports science. Fascinated by the topic of sports injuries, her dance experience has allowed her to gain relevant knowledge regarding different muscle group activations and it’s potential risks. Currently in her final year as an undergraduate, pursuing her Bachelor of Sport Science & Management (with Honours) at Nanyang Technological University.",
  },
  {
    image: team7,
    name: "Michelle Loo",
    occupation: "Trainer",
    discription:
      "Michelle is an undergraduate, pursuing her Bachelor's degree in Sport Science and Management at National Technological University which allows her to actively integrate what she has learnt into practice, and to effectively address your needs. Michelle has developed a strong enthusiasm toward health and the importance of functional movement in the human body through past experiences in Track & Field and Cross Country. These experiences have led her to develop an enjoyment toward Recreational Running, High Intensity Interval Training (HIIT) Cardio and Strength Training. Moreover, she has developed a better understanding toward the body in totality and how muscle groups work together to support functional movement. ",
  },
  {
    image: team8,
    name: "Norika Tan",
    occupation: "Marketing Executive",
    discription:
      "Norika is a marketing intern who is currently studying logistics supply chain and marketing for her bachelors degree. She manages the company’s social media and is in charge of editing videos and photos uploaded in Instagram.",
  },
];

export default TeamMemberJson;
