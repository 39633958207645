import React from "react";
import image from "../../assets/images/ourstory.png";
const OurStory = () => {
  return (
    <>
      <section style={{ backgroundColor: "var(--light-gray-clr)" }}>
        <div className="container">
          <div className="text-center">
            <h1 className=" font-25px font-md-36px font-weight-600 primary-text">
              OUR STORY
            </h1>
            <p className=" font-18px font-md-20px">
              Emile Dumont founded Bodynetic (previously The Posture Lab) in
              July 2017, after working in the fitness and wellness industry and
              realising that no one was looking out in areas of preventive
              training and awareness to reduce chronic pains and posture
              problems. He decided to take action and do something about it.
            </p>
            <p className=" font-18px font-md-20px">
              That was how Bodynetic become the first model delivering
              high-quality customised and curated programs at scale by
              empowering individuals to take action to improve their lifestyles.
              Bodynetic believes in creating social impact through
              inclusiveness, accessibility. Providing new efficiencies to
              individuals and companies.
            </p>
          </div>
          <br />

          <img
            src={image}
            alt=""
            className=" border-radius-10px"
            style={{ filter: "blur(10px)" }}
          />
        </div>
      </section>
    </>
  );
};

export default OurStory;
