import React from "react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
import TeamMemberJson from "../jsonData/TeamMemberJson";
const TeamMembers = () => {
  // function truncate(str, n) {
  //   return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  // }
  const SmartText = ({ text, index, length = 270 }) => {
    const [showLess, setShowLess] = React.useState(true);
    if (text.length < length) {
      return <p>{text}</p>;
    }

    return (
      <>
        <p className="mb-1">
          {showLess ? `${text.slice(0, length)}...` : text}
        </p>
        <ul className="mb-4">
          <li>
            <a
              href="#."
              data-bs-toggle="modal"
              data-bs-target={`#modalId${index}`}
              className="primary-anchor ms-n1"
            >
              &nbsp;View {showLess ? "More" : "Less"}
            </a>
          </li>
        </ul>
      </>
    );
  };
  return (
    <>
      <section
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
        className="px-4 px-lg-0"
      >
        <div className="text-center">
          <h1 className=" font-25px font-md-36px font-weight-600 primary-text">
            MEET OUR TEAM MEMBERS
          </h1>
        </div>
        <br />
        <br />
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={20}
          slidesPerView={1}
          autoHeight={true}
          loop={true}
          speed={1200}
          navigation
          grabCursor
          pagination
          className="teamSlider padding-inline-lg-10vw"
          style={{ overflow: "inherit" }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
        >
          {TeamMemberJson.map((value, index) => (
            <>
              <SwiperSlide key={index} className="h-auto">
                <div
                  className="teamCard p-4"
                  style={{ backgroundColor: "var(--light-gray-clr)" }}
                >
                  <div className="row h-100">
                    <div className="col-lg-3">
                      <img
                        src={value.image}
                        alt=""
                        className=" border-radius-8px"
                        style={{
                          objectFit: "cover",
                          objectPosition: "center center",
                          height: "378px",
                        }}
                      />
                    </div>
                    <div className="col-lg-9">
                      <i className="bi bi-quote primary-text font-50px font-md-56px ms-n2 ms-md-n2"></i>
                      <div>
                        <p
                          className=" font-18px"
                          style={{ lineHeight: "32px", display: "inline" }}
                        >
                          <SmartText text={value.discription} index={index} />
                        </p>
                      </div>
                      <h2 className=" font-28px font-weight-600  primary-text">
                        {value.name}
                      </h2>
                      <h5 className=" font-18px font-weight-600 opacity-06">
                        {value.occupation}
                      </h5>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
        {TeamMemberJson.map((value, index) => (
          <>
            <div
              className="modal fade"
              id={`modalId${index}`}
              tabIndex="-1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              role="dialog"
              aria-labelledby="modalTitleId"
              aria-hidden="true"
              key={index}
            >
              <div
                className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalTitleId">
                      Bodynetic{" "}
                      <span className="primary-text">Team Members</span>
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row g-4">
                      <div className="col-lg-4">
                        <img
                          src={value.image}
                          alt={value.name}
                          className="border-radius-10px width-65 mx-auto width-md-100 "
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </div>
                      <div className="col-lg-8">
                        <i className="bi bi-quote primary-text font-56px ms-n2"></i>
                        <p
                          className=" font-18px"
                          style={{
                            lineHeight: "32px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {value.discription}
                        </p>
                        <br />
                        <h2 className=" font-28px font-weight-600  primary-text">
                          {value.name}
                        </h2>
                        <h5 className=" font-18px font-weight-600 opacity-06">
                          {value.occupation}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </section>
    </>
  );
};

export default TeamMembers;
