import React from "react";
import OurSolutionimage from "../../assets/images/oursolution.png";
const OurSolution = () => {
  return (
    <>
      <section className="light-gray-bg">
        <div className="container">
          <div className="row g-4 align-items-center g-4 justify-content-between">
            <div className="col-lg-5">
              <img
                src={OurSolutionimage}
                alt=""
                className="mx-auto width-70 width-md-50 width-lg-100"
              />
            </div>
            <div className="col-lg-6">
              <h3 className="font-20px font-weight-600 text-uppercase">
                OUr Solution
              </h3>
              <h2 className=" font-25px font-md-36px font-weight-700 primary-text text-uppercase mb-3">
                AI-Driven Technology
              </h2>
              <p className=" font-17px">
                Bodynetic provides you with an end to end AI-enabled experience
                to dealing with chronic pain, posture issues and improving your
                overall lifestyle.
              </p>
              <p className=" font-17px">
                Integrating simple to do exercises that can provide live
                feedback both audio and visual during your sessions and enable
                you to improve your overall wellbeing.
              </p>
              <p className=" font-17px">
                We bring the experience of the gym right to your smartphone.
                Easy to use and hassle free!
              </p>
              <ul className=" mt-4">
                <li>
                  <a href="#" className="secondary-btn extra-btn-padding-25">
                    Learn More
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSolution;
