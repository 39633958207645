import React from "react";
import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
const Faq = () => {
  return (
    <>
      <section
        className="circlr-bg-shap overflow-hidden"
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
      >
        <div className="container position-relative">
          <div className="text-start width-100">
            <h3 className=" font-25px font-md-36px font-weight-600 primary-text">
              FREQUENTLY ASKED QUESTIONS
            </h3>
          </div>
          <br />
          <div className="accordion according-style-1" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="heading1">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-1"
                  aria-expanded="true"
                  aria-controls="collapse1"
                >
                  Question 1: What does Bodynetic do?
                </button>
              </h2>
              <div
                id="collapse-1"
                className="accordion-collapse collapse show"
                aria-labelledby="heading"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  <p>
                    1. We are a digital health and fitness solution that focuses
                    on reduces chronic pain, posture issues and improve your
                    overall lifestyle and performance. Based in Singapore and
                    are catering to clients all around the world.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-2"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question 2: Is this app credible?
                </button>
              </h2>
              <div
                id="collapse-2"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  2. Developed by a team of Sports Science practitioners with
                  over 40 years of experience as sports trainers, strength and
                  conditioning and high performance coaches.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-3"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question 3: How long are the programmes?
                </button>
              </h2>
              <div
                id="collapse-3"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  <p className="mb-3">
                    1. We cater to individuals and/or corporates from that range
                    from the sedentary, not so active and weekend athletes and
                    high performance performers and athletes.
                  </p>
                  <p>
                    2. Our programme is a unique ongoing process from pain
                    reduction, lifestyle education to strength and conditioning
                    and in the longer term getting you performing at your best.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-4"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question 4: How does the app work?
                </button>
              </h2>
              <div
                id="collapse-4"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  <p className="mb-3">
                    1. You will be provided with a customised exercise plan
                    based on your posture analysis and your issues highlighted
                    by our AI detection. As you improve, you will be provided
                    with more challenging exercises accordingly.
                  </p>
                  <p className="mb-3">
                    2. Do the customised exercises anytime and anywhere all with
                    your smartphone.
                  </p>
                  <p className="mb-3">
                    3. Get real time live feedback from our AI motion tracking
                    technology as you do the exercises. Improving your
                    confidence in performing these exercises yourself.
                  </p>
                  <p className="mb-3">
                    4. Should you want an even more in depth analysis and manage
                    specific issues. You may subscribe to our premium tier to
                    speak with our sports trainers and strength and conditioning
                    coaches.
                  </p>
                  <p>
                    4. Should you want an even more in depth analysis and manage
                    specific issues. You may subscribe to our premium tier to
                    speak with our sports trainers and strength and conditioning
                    coaches.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-5"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question 5: Who is this app suitable for?
                </button>
              </h2>
              <div
                id="collapse-5"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  <p className="mb-3">The app is most suitable for:</p>
                  <p>1. Desk bound professionals with sedentary lifestyles</p>
                  <p>2. Individuals experience pain or posture issues</p>
                  <p>3. Athletes and performers</p>
                  <p>
                    4. Students experiencing pains and aches due to study and
                    exam stress
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-6"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Question 6: Who can I contact if I have questions?
                </button>
              </h2>
              <div
                id="collapse-6"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body pt-0">
                  <p>1. You may leave us a message through the app</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
