import image1 from "../../assets/images/icons/1.svg";
import image2 from "../../assets/images/icons/2.svg";
const WeDifferentJson = [
  {
    image: image1,
    title: "Exercise Library",
    discription:
      "We have an extensive and growing library of exercises we can analyse.",
  },
  {
    image: image2,
    title: "Accuracy",
    discription:
      "Our algorithm provides accuracy rivaling expensive sensor-based systems at a  fraction of price.",
  },
];

export default WeDifferentJson;
