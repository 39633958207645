import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = ({ ...prop }) => {
  const { pathname } = useLocation();
  const urldata = pathname.split("/")[1];
  return (
    <>
      <section
        className={`bganimate ${prop?.height ? `height-${prop.height}` : ""}`}
        style={{ backgroundColor: "var(--light-gray-clr)" }}
      >
        <div className="container">
          <div className="text-center">
            <h1 className=" font-40px font-weight-600 width-md-90 width-lg-70 mx-auto">
              {prop.title}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center align-items-center gap-2">
                <li className="breadcrumb-item">
                  <Link
                    to="/"
                    className="text-dark hover-opacity-06 font-14px font-weight-500"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-double-right font-14px"></i>
                </li>
                <li
                  className="breadcrumb-item text-dark font-14px font-weight-500"
                  aria-current="page"
                >
                  {urldata
                    ? urldata
                        .split("-")
                        .join(" ")
                        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase()
                        )
                    : ""}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
