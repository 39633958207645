import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "./themeContext";
import reportWebVitals from "./reportWebVitals";
import "./assets/bootstrap/scss/bootstrap.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./main.scss";
import "./assets/sass/utility/typography.scss";
import "./assets/css/media.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
