import React from "react";
import BrandsJson from "../jsonData/BrandsJson";
// Import Swiper React components
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const OurPartnerClient = () => {
  return (
    <>
      <section className="light-gray-bg">
        <div className="container">
          <div className="text-center">
            <h1 className=" font-25px font-md-36px font-weight-600">
              Our Partners & Clients
            </h1>
            <div className="hb-line mx-auto"></div>
          </div>
          <br />
          <br className=" d-none d-md-block" />
          <div className="coverBrandSlider">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={10}
              loop={true}
              grabCursor
              speed={1200}
              slidesPerView={2}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                576: {
                  // width: 576,
                  slidesPerView: 3,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 4,
                },
              }}
            >
              {BrandsJson.map((value, index) => (
                <SwiperSlide key={index}>
                  <img src={value.brandImage} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPartnerClient;
