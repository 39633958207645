import image1 from "../../assets/images/testimonial/1.png";
import image2 from "../../assets/images/testimonial/2.png";
import image3 from "../../assets/images/testimonial/3.png";
import image4 from "../../assets/images/testimonial/4.png";
const TestimonialJson = [
  {
    image: image1,
    name: "Daryl Chia",
    occupation: "National Jiu Jitsu Athlete & Account Executive",
    description:
      "I’ve been going to Bodynetic regularly since 2017. They have helped me tremendously with my recovery from my early days as a competitive Jiu Jitsu athlete and even now as I started working in the corporate sector, they have been a great help for all my desk bound related body stiffness. Bodynetic has also been an integral part of my strength and conditioning development and I definitely recommend their services.",
  },
  {
    image: image2,
    name: "Katherine Ng",
    occupation: "Head of APAC Marketing and Operations, TZ APAC",
    description:
      "I have been going to Bodynetic for a year now. I have gained valuable knowledge in knowing how my body works, from targeted muscle conditioning to improve my athletic performance, to overall improvement of my posture for day to day living. It's an integral part of my self-care and mobility work routine and I would highly recommend Bodynetic to high functioning C–level executives that wish to maximize their holistic wellness and performance.",
  },
  {
    image: image3,
    name: "Ronnie Chen",
    occupation:
      "Freelance Professional Dancer, Choreographer, Coach and Director",
    description:
      "I have been a regular at Bodynetic since 2018. As a dance artist, longevity in the form is often neglected. Bodynetic gave me constant guidance on how to balance out my body conditioning and more importantly provide targeted training to specific weaknesses. Never shying away from my questions and will always make an effort to educate and clarify why we are doing what we have going on. One of the biggest things that kept me training with them is that I get to meet them personally to set goals. We constantly review the progress and constantly adjust and improve the plan. For me, I’ve personally felt a stark improvement in mobility, motor skills and unlocked a bunch of range in motion. I highly recommend anyone that wants to improve their performance that requires a high level of physicality.      ",
  },
  {
    image: image4,
    name: "Lim Jia Yi",
    occupation: "Independent Dance Artist",
    description:
      "I have been going to Bodynetic for more than a year now. From targeted muscle conditioning to improve my fitness, to overall improvement of my physical and mental health. I appreciate that the trainers at the Bodynetic were able to identify the roots of my problems and offer me viable solutions to improve my body conditions. Their knowledge and sensitivity in treating every customer with care stands out and I would highly recommend Bodynetic to high performing artistic personnel looking for safe and reliable training space to maximize their holistic wellness and longevity in the performing arts career.",
  },
];

export default TestimonialJson;
