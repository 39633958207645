import React, { useContext, useEffect } from "react";
import { Themecontext } from "../../themeContext";

const ReactSwitch = () => {
  const { isdark, setdark } = useContext(Themecontext);
  const handleChange = () => {
    setdark(!isdark);
  };
  const check =
    isdark === false
      ? `${(document.body.className = "light")}`
      : `${(document.body.className = "dark")}`;

  return (
    <>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        onChange={handleChange}
        hidden
      />
      <label className="react-switch-label" htmlFor={`react-switch-new`}>
        <div className="d-flex align-items-center justify-content-between position-absolute top-0 start-0 w-100 h-100 border-radius-50px p-3">
          <span className="text-white font-14px">Light</span>
          <span className="text-white font-14px">Dark</span>
        </div>
        <span className={`react-switch-button`}>
          {isdark === false ? "light" : "dark"}
        </span>
      </label>
    </>
  );
};

export default ReactSwitch;
