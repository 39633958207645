import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import sliderimage from "../../assets/images/slider/1.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const HeroSlider = () => {
  return (
    <>
      <div className="mainslider">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[Autoplay, Navigation, Pagination]}
          loop={true}
          pagination
          navigation
          grabCursor
          speed={1200}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img
              src={sliderimage}
              alt=""
              className=" position-absolute top-0 start-0 w-100 "
              style={{ zIndex: -1, objectFit: "cover" }}
            />

            <div className="container zindex-1 h-100 d-flex align-items-center px-8 px-md-4">
              <div className="row g-4 justify-content-between align-items-center">
                <div className="col-lg-7">
                  <h4 className="font-weight-600 font-10px font-md-24px text-white">
                    <span></span>
                    <span></span>
                    <span></span>
                    DIGITAL HEALTH APP
                  </h4>
                  <h2 className="font-weight-700 font-16px font-md-28px lh-base font-md-40px text-white text-uppercase">
                    Preventing{" "}
                    <span className=" primary-text">pain improving</span>{" "}
                    Lifestyles
                  </h2>

                  <ul className="mt-3">
                    <li>
                      <a
                        className="secondary-btn font-13px font-md-20px shadow-btn font-weight-700 extra-btn-padding-25"
                        href="#contact"
                      >
                        GET IN TOUCH!
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={sliderimage}
              alt=""
              className=" position-absolute top-0 start-0 w-100 "
              style={{ zIndex: -1, objectFit: "cover" }}
            />
            <div className="container zindex-1 h-100 d-flex align-items-center px-8 px-md-4">
              <div className="row g-4 justify-content-between align-items-center">
                <div className="col-lg-7">
                  <h4 className="font-weight-600 font-10px font-md-24px text-white">
                    <span></span>
                    <span></span>
                    <span></span>
                    DIGITAL HEALTH APP
                  </h4>
                  <h2 className="font-weight-700 font-16px font-md-28px lh-base font-md-40px text-white text-uppercase">
                    Preventing{" "}
                    <span className=" primary-text">pain improving</span>{" "}
                    Lifestyles
                  </h2>

                  <ul className="mt-3">
                    <li>
                      <a
                        className="secondary-btn font-16px font-md-20px shadow-btn font-weight-700 extra-btn-padding-25"
                        href="#contact"
                      >
                        GET IN TOUCH!
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default HeroSlider;
