import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../component/Breadcrumb/Breadcrumb";
import FocusCard from "../component/focus/FocusCard";

import PostDetection from "../component/postDetection/PostDetection";
import { Themecontext } from "../themeContext";
import scaneposture from "../assets/images/scanposture.png";
import scaneposturedark from "../assets/images/scanposturedark.png";
import resultposture from "../assets/images/resultposture.png";
import resultposturedark from "../assets/images/resultposturedark.png";
const HowWorkPage = () => {
  const { isdark, setdark } = useContext(Themecontext);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );

  //console.log(matches);

  useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      <Breadcrumb height="70vh" title="Our Focus" />
      <FocusCard />
      <br />
      <br />
      <br />
      <br />
      <br />

      <section
        style={{ backgroundColor: "var(--light-gray-clr)" }}
        className="bganimate py-5"
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <img
                src={isdark === true ? scaneposturedark : scaneposture}
                alt=""
                style={{
                  height: "525px",
                  objectFit: "contain",
                  marginTop: `${matches === true ? "-13rem" : "0rem"}`,
                }}
              />
            </div>
            <div className="col-lg-6">
              <h2 className=" primary-text font36px font-weight-700 mb-4">
                Scanning Posture
              </h2>
              <p className=" font-20px">
                Using the AI developed body scanner from the camera on the
                phone. To effectively scan your entire body posture.
              </p>
            </div>
          </div>
        </div>
      </section>
      <PostDetection />
      <br />

      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <br className="d-none d-lg-block" />
      <section
        style={{ backgroundColor: "var(--light-gray-clr)" }}
        className="bganimate py-5"
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6 order-lg-1">
              <img
                src={isdark === true ? resultposturedark : resultposture}
                alt=""
                style={{
                  height: "525px",
                  objectFit: "contain",
                  marginTop: `${matches === true ? "-13rem" : "0rem"}`,
                }}
              />
            </div>
            <div className="col-lg-6">
              <h2 className=" primary-text font36px font-weight-700 mb-4">
                Result Of Posture
              </h2>
              <ul className="disc-items">
                <li className="font-20px">
                  Results of the scan will pin point problems areas of the
                  posture.
                </li>
                <li className="font-20px">
                  With these results, exercises will be proposed to you to
                  improve your posture.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default HowWorkPage;
