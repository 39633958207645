import React from "react";
import { Link } from "react-router-dom";
import footerlogo from "../../assets/images/footerlogo.png";
import SocialLinksJson from "../jsonData/SocialLinksJson";
const Footer = () => {
  return (
    <>
      <section className="footer-bg pb-0">
        <div className="container">
          <div className="row g-5 g-md-4 justify-content-between">
            <div className="col-lg-3">
              <br />
              <br />
              <img
                src={footerlogo}
                alt="footer logo"
                className="width-60 width-md-40 width-lg-90"
              />
              <br />
              <br />
              <h3 className=" font-32px font-weight-600 text-white text-uppercase">
                Get in Touch!
              </h3>
              <div className="hb-line"></div>
              <br />
              <p className=" text-white">
                Subscribe to out new letter and get all the latest updates and
                trends ahead of the time.
              </p>
              <div className="input-group d-flex emailsend">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your Email address"
                />
                <button type="submit" className=" secondary-btn">
                  Send
                </button>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="row g-4 align-items-start">
                <div className="col-lg-12 col-6">
                  <h3 className=" font-24px font-weight-600 text-white text-uppercase">
                    Website Links
                  </h3>
                  <div className="hb-line"></div>
                  <br />
                  <ul className="footer-links">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/how-it-works">How it works?</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ’s</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-12 col-6">
                  <br className="d-none d-lg-block" />
                  <br className="d-none d-lg-block" />
                  <h3 className=" font-24px font-weight-600 text-white text-uppercase">
                    Other Links
                  </h3>
                  <div className="hb-line"></div>
                  <br />
                  <ul className="footer-links">
                    <li>
                      <Link to="/privacypolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/termscondition">Terms & Condition</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="footerFormCard card border-0 border-radius-12px overflow-hidden "
                id="contact"
              >
                <div className="card-header primary-bg text-center p-3">
                  <h1 className=" font-25px font-md-36px text-white m-0">
                    GET IN TOUCH!
                  </h1>
                </div>
                <div className="card-body p-4">
                  <form action="" className="pb-4">
                    <div className="form-group">
                      <label className=" font-16px font-weight-700">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className=" form-control"
                        placeholder="Your Full Name"
                      />
                    </div>
                    <div className="form-group">
                      <label className=" font-16px font-weight-700">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className=" form-control"
                        placeholder="Your Email Address"
                      />
                    </div>
                    <div className="form-group">
                      <label className=" font-16px font-weight-700">
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        className=" form-control"
                        placeholder="Your Mobile No."
                      />
                    </div>
                    <div className="form-group">
                      <label className=" font-16px font-weight-700">
                        Message
                      </label>
                      <textarea
                        rows="5"
                        className=" form-control"
                        placeholder="Type your message here...."
                      ></textarea>
                    </div>
                    <div className="text-center">
                      <br />
                      <button
                        type="submit"
                        className=" primary-btn extra-btn-padding-40"
                      >
                        SEND
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <br />
              <br />
              <div className="sociaMediaLinks d-lg-none">
                <ul className="d-flex align-items-center justify-content-around">
                  {SocialLinksJson.map((value, index) => (
                    <li key={index}>
                      <a href={value.socialLink} target="_blank">
                        <i className={value.socialName}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="sociaMediaLinks d-none d-lg-block position-absolute top-50 translate-middle-y"
                style={{ right: "calc(100vw - 99vw)" }}
              >
                <ul>
                  <li>
                    <span></span>
                  </li>

                  {SocialLinksJson.map((value, index) => (
                    <li key={index}>
                      <a href={value.socialLink} target="_blank">
                        <i className={value.socialName}></i>
                      </a>
                    </li>
                  ))}
                  <li>
                    <span></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div className="copyright text-center border-top border-white mt-3 mt-md-5 py-3">
            <p className="m-0 text-white">© Copyrights The Bodynetic 2022.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
