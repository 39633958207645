import React, { useContext } from "react";
import postDetection from "../../assets/images/postDetection.png";
import postDetectiondark from "../../assets/images/postDetectiondark.png";
import bgpatternImg from "../../assets/images/shap/bg-shap1.png";
import { Themecontext } from "../../themeContext";
const PostDetection = () => {
  const { isdark, setdark } = useContext(Themecontext);
  return (
    <>
      <section
        style={{ background: `url(${bgpatternImg}) no-repeat center/cover` }}
      >
        <div className="container">
          <div className="text-center width-100">
            <h3 className=" font-25px font-md-36px font-weight-600">
              AI POSE DETECTION
            </h3>
            <div className="hb-line mx-auto"></div>
            <p className=" font-20px font-weight-400">
              During the scan, instructions will be given to scan the front and
              side standing positions to <br /> detect posture deviations and
              imbalances.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <img
                src={isdark === true ? postDetectiondark : postDetection}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostDetection;
