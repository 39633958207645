import React from "react";
import { Link } from "react-router-dom";
import faqimg from "../assets/images/faq.png";
import faqbannerimg from "../assets/images/faqbanner.jpg";
import Faq from "../component/faq/Faq";
const FaqPage = () => {
  return (
    <>
      <section
        className="banner w-100 height-40vh height-lg-80vh d-flex align-items-center justify-content-center"
        style={{
          background: `url(${faqbannerimg}) no-repeat center/cover`,
        }}
      >
        <div className="container">
          <div className="text-center">
            <h2 className=" font-36px font-weight-400 text-white">
              FREQUENTLY ASKED QUESTIONS
            </h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center align-items-center gap-2">
                <li className="breadcrumb-item">
                  <Link
                    to="/"
                    className="text-white hover-opacity-06 font-14px font-weight-500"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-double-right text-white font-14px"></i>
                </li>
                <li
                  className="breadcrumb-item text-white font-14px font-weight-500"
                  aria-current="page"
                >
                  FAQ’s
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="p-0">
        <div className="row g-0">
          <div className="col-lg-6">
            <img
              src={faqimg}
              alt=""
              style={{ height: "103%", objectFit: "cover", marginTop: "-7px" }}
              className="d-none d-md-block"
            />
          </div>
          <div className="col-lg-6">
            <div className="p-4">
              <Faq />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqPage;
